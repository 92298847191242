import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import firebase from "gatsby-plugin-firebase"
import GetStarted from "../../components/sections/getstarted"
import SupportDay from "../../components/sections/supportday"
import { navigate } from "gatsby"
import Confetti from "react-confetti"
import { AuthContext } from "../../context/auth"
import ProofOfWork from "../../components/sections/proofofwork"

import { Section, Container } from "../global"

const Profile = (props) => {
  const [count, setCount] = useState(0)
  const [userDocumentId, setUserDocumentId] = useState(0)
  const [userUid, setUserUid] = useState(0)
  const [userIntentions, setUserIntentions] = useState([])
  const [userAvatar, setUserAvatar] = useState(
    "https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Floading.gif?alt=media&token=a3cbd288-ea7e-48af-a691-d52a07fd7ff9"
  )
  const [displayUsername, setDisplayUsername] = useState("")
  const [currentStreak, setCurrentStreak] = useState(0)
  const [longestStreak, setLongestStreak] = useState(0)
  const [supportersCount, setSupportersCount] = useState(0)

  const [confettiOpacity, setConfettiOpacity] = useState(0)
  const [confettiRun, setConfettiRun] = useState(false)

  const [giveCheersButtonDisabled, setGiveCheersButtonDisabled] = useState(
    false
  )
  const [cheersToGive, setCheersToGive] = useState([])
  const [cheersToGiveCount, setCheersToGiveCount] = useState(0)
  const [userEmail, setUserEmail] = useState("")

  const currentUser = useContext(AuthContext)

  const giveCheers = async () => {
    setGiveCheersButtonDisabled(true)

    var givingUserName = ""

    await firebase
      .firestore()
      .collection("users")
      .where("uid", "==", currentUser.user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log("doc data = ", doc.data())
          givingUserName = doc.data().displayUsername
        })
      })
      .catch((error) => {
        console.log("Error getting documents: ", error)
      })

    console.log("givingUserName = ", givingUserName)

    console.log("cheerztogive ID", cheersToGive[0])
    var cheersRef = firebase
      .firestore()
      .collection("cheers")
      .doc(cheersToGive[0])

    return cheersRef
      .update({
        ownerId: userUid,
      })
      .then(async () => {
        console.log("Document successfully updated!")
        setCheersToGiveCount(cheersToGiveCount - 1)
        setConfettiOpacity(1)
        setConfettiRun(true)

        var data = []

        data = [
          {
            givingUserName: givingUserName,
            receivingUserName: displayUsername,
            recievingUserEmail: userEmail,
          },
        ]

        await firebase
          .app()
          .functions("us-central1")
          .httpsCallable("sendGiveCheerzEmail")(data)
          .then((response) => {
            console.log("cheerz email sent")
          })
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error)
      })
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var localCheersToGive = []

        firebase
          .firestore()
          .collection("cheers")
          .where("ownerId", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              localCheersToGive.push(doc.id)
            })
            console.log("cheers to give", localCheersToGive.length)
            setCheersToGive(localCheersToGive)
            setCheersToGiveCount(localCheersToGive.length)
          })
          .catch((error) => {
            console.log("Error getting documents: ", error)
          })
      } else {
        console.log("no user")
      }
    })

    const intentionsArray = []
    var localUserDocId = ""
    firebase
      .firestore()
      .collection("users")
      .where("username", "==", props.username.toLowerCase())
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          setCount(doc.data().perfectDayCount)
          setUserDocumentId(doc.id)
          localUserDocId = doc.id
          setUserUid(doc.data().uid)
          setSupportersCount(doc.data().supportersCount)
          setCurrentStreak(doc.data().currentStreak)
          setLongestStreak(doc.data().longestStreak)
          setUserAvatar(doc.data().avatarURL)
          setDisplayUsername(doc.data().displayUsername)
          setUserEmail(doc.data().email)
        })
      })
      .then(() => {
        console.log("I work dood")
        firebase
          .firestore()
          .collection("intentions")
          .where("setters", "array-contains", localUserDocId)
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              const { name, description, icon, setters } = doc.data()
              const id = doc.id

              intentionsArray.push({
                id,
                name,
                description,
                icon,
                setters,
              })
            })

            setUserIntentions(intentionsArray)
          })
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error)
      })
  }, [])

  return (
    <>
      <Section style={{ backgroundColor: "#f8f8f8", paddingBottom: "20px" }}>
        <StyledContainer>
          <Subtitle>Practice Daily. Earn Bitcoin.</Subtitle>
          <ImageWrapper>
            <div className="profileImage">
              <img src={userAvatar} alt="Avatar" />
            </div>
          </ImageWrapper>

          <SectionTitle>{displayUsername}</SectionTitle>
        </StyledContainer>
      </Section>
      <Section style={{ paddingTop: "20px" }}>
        <StyledContainer>
          <FeaturesGrid>
            <FeatureItem>
              <FeatureTitle>
                Total Days
              </FeatureTitle>
              <FeatureText>{count}</FeatureText>
            </FeatureItem>
          </FeaturesGrid>
          <center>
            <FeatureTitle style={{ marginTop: "60px", marginBottom: "30px" }}>
              <img
                style={{
                  maxHeight: "36px",
                  width: "auto",
                  position: "relative",
                  top: "6px",
                  marginRight: "3px",
                }}
                alt="Feature"
                src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Ficons%2Fintention.png?alt=media&token=6446f53a-ffe0-4e4b-a83d-1f59c185ce5e"
              />
              Intentions
            </FeatureTitle>
          </center>
          {userIntentions.map((l, i) => (
            <IntentionItem key={i}>
              <div className="intentionWrapper">
                <img src={l.icon} className="goldIntentions" alt="Intentions" />
                <div className="intentionCopy">
                  <IntentionTitle>{l.name}</IntentionTitle>
                  <IntentionText>{l.description}</IntentionText>
                </div>
              </div>
            </IntentionItem>
          ))}
        </StyledContainer>
      </Section>
      <SupportDay state={{ username: displayUsername }} />
      <GetStarted />
      <ProofOfWork />
    </>
  )
}

export default Profile

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const IntentionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const IntentionTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0px;
  margin-top: 10px;
  padding: 0px;
  float: left;
`

const FeatureText = styled.p`
  text-align: center;
`
const IntentionText = styled.p`
  text-align: left;
  float: left;
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`
const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
