import React from "react"
import Layout from "../../components/common/layout/layout"
import Navigation from "../../components/common/navigation/navigation"
import SEO from "../../components/common/layout/seo"
import Profile from "../../components/sections/profile"
import Footer from "../../components/sections/footer"

export default function Member({ params }) {
  // if the user visits `/past-order/123`, then id here is `123`

  console.log("params", params);

  return(
    <Layout>
      <script src="https://www.gstatic.com/firebasejs/8.0.0/firebase-app.js"></script>
      <Navigation />
      <SEO 
        title={params.username} 
      />
      <Profile username={params.username} />
      <Footer />
    </Layout >
  )
}